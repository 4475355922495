import React, { useRef, useEffect, useCallback, useState } from 'react';
import './PhoneTextScroll.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function PhoneTextScroll() {
  const tl = useRef();
  useEffect(() => {
    ScrollTrigger.matchMedia({
      '(min-width:768px)': function () {
        const panels = gsap.utils.toArray('.phonescroll-block-container');
        panels.forEach((panel) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: panel,
              start: 'top top',
              end: '+=200%',
              scrub: 0.6,
              pin: true,
              markers: false,
            },
          });

          const content = panel.querySelector('.text-scroll-wrapper');

          tl.current.to(content, {
            y: '-65%',
          });

          const scrollTexts = gsap.utils.toArray('.anim-text');

          scrollTexts.forEach((scrollItem, index) => {
            tl.current = gsap.timeline({
              scrollTrigger: {
                trigger: scrollItem,
                scrub: 0.6,
                start: 'top 30%', // start when top of trigger target hits 50% point of viewport
                end: '+=150px',

                onUpdate: () => {
                  if (animphoneScroll.current) {
                    if (index === 0) {
                      animphoneScroll.current.classList.add(`active-0`);
                      animphoneScroll.current.classList.remove(`active-1`);
                      animphoneScroll.current.classList.remove(`active-2`);
                    }
                    if (index === 1) {
                      animphoneScroll.current.classList.remove(`active-0`);
                      animphoneScroll.current.classList.add(`active-1`);
                      animphoneScroll.current.classList.remove(`active-2`);
                    }
                    if (index === 2) {
                      animphoneScroll.current.classList.remove(`active-0`);
                      animphoneScroll.current.classList.remove(`active-1`);
                      animphoneScroll.current.classList.add(`active-2`);
                    }
                  }
                },

                markers: false,
              },
            });
          });

          const scrollTextsTwo = gsap.utils.toArray('.anim-text-2');

          scrollTextsTwo.forEach((item, indexss) => {
            tl.current = gsap.timeline({
              scrollTrigger: {
                trigger: item,
                scrub: 0.6,
                start: 'top 30%', // start when top of trigger target hits 50% point of viewport
                end: '+=150px',

                onUpdate: () => {
                  if (animphoneScrollTwo.current) {
                    if (indexss === 0) {
                      animphoneScrollTwo.current.classList.add(`active-0`);
                      animphoneScrollTwo.current.classList.remove(`active-1`);
                      animphoneScrollTwo.current.classList.remove(`active-2`);
                    }
                    if (indexss === 1) {
                      animphoneScrollTwo.current.classList.remove(`active-0`);
                      animphoneScrollTwo.current.classList.add(`active-1`);
                      animphoneScrollTwo.current.classList.remove(`active-2`);
                    }
                    if (indexss === 2) {
                      animphoneScrollTwo.current.classList.remove(`active-0`);
                      animphoneScrollTwo.current.classList.remove(`active-1`);
                      animphoneScrollTwo.current.classList.add(`active-2`);
                    }
                  }
                },

                markers: false,
              },
            });
          });
        });

        gsap.set('.phone-text-scroll-main', {
          zIndex: (i, target, targets) => targets.length - i,
        });
      },
    });
  }, [tl]);

  const animphoneScroll = useRef();
  const animphoneScrollTwo = useRef();

  // useLayoutEffect(() => {

  // }, [tl]);

  // useLayoutEffect(() => {

  // }, [tl]);

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1200);
  }, []);

  const [phoneTextAnim, setphoneTextAnim] = useState(0);
  const updatePhoneTextAnim = useCallback(() => {
    setphoneTextAnim((state) => (state === 2 ? 0 : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updatePhoneTextAnim(), 2200);

    return () => clearInterval(interval);
  }, [updatePhoneTextAnim]);

  return (
    <>
      <div className="phonescroll-block-container">
        <div className="phone-text-scroll-main">
          <div className="container">
            <div className="row">
              <div className="col-md col-8 offset-2">
                <h2 className="heading text-center">
                  You&apos;ll wonder how we're making money.
                </h2>
                <div className="flex justify-center">
                  <div
                    className="phone-image anim-phone-image"
                    ref={animphoneScroll}>
                    <img src="/img/phone-scroll-1.png" alt="" />

                    <div className="phone-scroll-anim-container">
                      {[
                        {
                          id: 0,
                          title: 'Annual Fees',
                          svgtext: 'There are no annual fees',
                        },
                        {
                          id: 1,
                          title: 'Joining fees',
                          svgtext: 'There are no joining fees',
                        },
                        {
                          id: 2,
                          title: 'Hidden charges',
                          svgtext: 'There are no hidden charges',
                        },
                      ].map(({ id, title, svgtext }) => (
                        <div
                          key={id}
                          className={`single-anim-scroll ${
                            id === phoneTextAnim ? 'active' : ''
                          }`}>
                          <p>{title}</p>

                          <div className="scroll-anim-block">
                            <svg
                              width="25"
                              height="25"
                              fill="none"
                              viewBox="0 0 25 25">
                              <rect
                                width="24"
                                height="24"
                                x=".602"
                                y=".576"
                                fill="#3BB900"
                                rx="8.913"
                              />
                              <path
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.124"
                                d="M18.221 12.059v.517a5.619 5.619 0 1 1-3.332-5.136"
                              />
                              <path
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.124"
                                d="m18.221 8.08-5.619 5.625-1.685-1.686"
                              />
                            </svg>

                            <span>{svgtext}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="phone-text-block-desc">
                    <div className="text-scroll-wrapper">
                      {[
                        {
                          id: 0,
                          title: (
                            <>
                              NO <span className="gold">annual fees. </span>
                            </>
                          ),
                        },
                        {
                          id: 1,
                          title: (
                            <>
                              NO <span className="gold">joining fees.</span>
                            </>
                          ),
                        },
                        {
                          id: 2,
                          title: (
                            <>
                              NO <span className="gold">hidden charges.</span>
                            </>
                          ),
                        },
                      ].map(({ id, title }) => (
                        <div
                          key={id}
                          className={`single-phone-text anim-text ${
                            id === phoneTextAnim ? 'active' : ''
                          }`}>
                          <h4>{id + 1}.</h4>
                          <p>{title}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="phonescroll-block-container">
        <div className="phone-text-scroll-main">
          <div className="container">
            <div className="row">
              <div className="col-md col-8 offset-2">
                <h2 className="heading text-center">
                  Because freedom to dream is free
                </h2>
                <div className="flex justify-center">
                  <div
                    className="phone-image anim-phone-image second-asset"
                    ref={animphoneScrollTwo}>
                    <img src="/img/phone-scroll-2.png" alt="" />

                    <div className="phone-scroll-anim-container">
                      {[
                        {
                          id: 0,
                          title: <>Free Transfer</>,
                          img: '/img/free-in-app-transfer.svg',
                        },
                        {
                          id: 1,
                          title: <>Free Incoming International Wire Transfer</>,
                          img: '/img/free-wire-transfer-international.svg',
                        },
                        {
                          id: 2,

                          img: '/img/free-aeldra-pay-realtime.png',
                        },
                      ].map(({ id, title, img }) => (
                        <div
                          key={id}
                          className={`single-anim-scroll ${
                            id === phoneTextAnim ? 'active' : ''
                          }`}
                          style={{ transform: 'none' }}>
                          <p>{title}</p>

                          <img src={img} alt="" />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="phone-text-block-desc">
                    <div className="text-scroll-wrapper">
                      {[
                        {
                          id: 0,
                          title: (
                            <>
                              Free{' '}
                              <span className="gold">In-App ACH Transfers</span>
                            </>
                          ),
                        },
                        {
                          id: 1,
                          title: (
                            <>
                              Free{' '}
                              <span className="gold">
                                Incoming International Wire Transfers
                              </span>
                            </>
                          ),
                        },
                        {
                          id: 2,
                          title: (
                            <>
                              Free{' '}
                              <span className="gold">
                                AeldraPay real-time Peer-to-Peer Transfers
                              </span>
                            </>
                          ),
                        },
                      ].map(({ id, title }) => (
                        <div
                          key={id}
                          className={`single-phone-text anim-text-2 ${
                            id === phoneTextAnim ? 'active' : ''
                          }`}>
                          <h4>{id + 1}.</h4>
                          <p>{title}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneTextScroll;
