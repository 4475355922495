import React, { useRef, useEffect, useState, useCallback } from 'react';

import gsap from 'gsap';
import Lottie from 'lottie-web';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Layout from '../components/Layout';
import '../styles/homepage.scss';
import HeroSectionHome from '../components/Home/HeroSectionHome/HeroSectionHome';
import HomeSectionOne from '../components/Home/HomeSection1/HomeSectionOne';
import Accordion from '../components/Accordion/Accordion';
import PhoneTextScroll from '../components/Home/PhoneTextScroll/PhoneTextScroll';
import { homeCtaContent, homeMarqueeContent } from '../utils/contentProvider';
import Button from '../components/Button/Button';
import SEO from '../components/SEO';
import NewsBlock from '../components/NewsBlock/NewsBlock';
import useMediaQuery from '../hooks/useMediaQuery';

gsap.registerPlugin(ScrollTrigger);

function Homepage() {
  const breakpoints = useBreakpoint();
  const ProcessAnimOne = useRef();
  const ProcessAnimTwo = useRef();
  const ProcessAnimThree = useRef();
  const debitCard = useRef();
  const timelineLine = useRef();
  const timelineBlock = useRef();

  const isMobile = useMediaQuery('(max-width: 767px)');

  // const [testimonialImage, setTestimonialImage] = useState();
  // const [testimoialDesc, settestimoialDesc] = useState();

  const [VerticalTextAnim, setVerticalTextAnim] = useState(0);
  const [VerticalTextAnimTwo, setVerticalTextAnimTwo] = useState(0);

  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const marqueeSliderContainer = document.querySelector('.marquee-slider');

      if (marqueeSliderContainer) {
        tns({
          container: marqueeSliderContainer,
          autoplay: true,
          autoplayTimeout: 0,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          gutter: 20,
          speed: 4000,
          controls: false,
          autoWidth: true,
          nav: false,
          preventActionWhenRunning: true,

          responsive: {
            0: {
              items: 2,
            },

            768: {
              items: 2,
            },

            1200: {
              items: 3.5,
            },
          },
        });
      }
      // const testimonialImageContainer = document.querySelector(
      //   '.testimonial-slider',
      // );

      // if (testimonialImageContainer) {
      //   const testimonialImageSlider = tns({
      //     container: testimonialImageContainer,
      //     autoplay: false,
      //     autoplayButtonOutput: false,
      //     mouseDrag: false,
      //     loop: true,
      //     gutter: 0,
      //     speed: 500,
      //     nav: false,
      //     controls: true,
      //     controlsContainer: controlTesimonial.current,

      //     responsive: {
      //       0: {
      //         items: 1,
      //       },

      //       768: {
      //         items: 1,
      //       },

      //       1200: {
      //         items: 1,
      //       },
      //     },
      //   });
      // }
      const testimonialContentContainer = document.querySelector(
        '.testimonial-slider-content',
      );

      if (testimonialContentContainer) {
        tns({
          container: testimonialContentContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          gutter: 0,
          speed: 500,

          // controlsContainer: controlTesimonial.current,

          responsive: {
            0: {
              items: 1,
              controls: true,
              nav: false,
              controlsPosition: 'bottom',
            },

            1210: {
              nav: false,
              controls: true,
              items: 1,
            },
          },
        });
      }

      const processSliderContainer = document.querySelector('.process-slider');

      if (processSliderContainer) {
        tns({
          container: processSliderContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          gutter: 0,
          speed: 500,
          controlsPosition: 'bottom',

          // controlsContainer: controlTesimonial.current,

          responsive: {
            0: {
              items: 1,
              controls: true,
              nav: false,
              navPosition: 'bottom',
            },

            768: {
              disable: true,
            },
          },
        });
      }

      const securitySliderContainer =
        document.querySelector('.security-slider');

      if (securitySliderContainer) {
        tns({
          container: securitySliderContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          gutter: 0,
          speed: 500,
          controlsPosition: 'bottom',

          // controlsContainer: controlTesimonial.current,

          responsive: {
            0: {
              items: 1,
              controls: true,
              nav: false,
              navPosition: 'bottom',
            },

            768: {
              items: 3,
            },
          },
        });
      }
    });
  }, []);

  const updateVerticaltextAnim = useCallback(() => {
    setVerticalTextAnim((state) => (state === 1 ? 0 : state + 1));
    setVerticalTextAnimTwo((state) => (state === 2 ? 0 : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updateVerticaltextAnim(), 4000);

    return () => clearInterval(interval);
  }, [updateVerticaltextAnim]);

  useEffect(() => {
    Lottie.loadAnimation({
      container: ProcessAnimOne.current,
      path: '/lottie/process-1.json',
      loop: true,
      autoplay: true,
      renderer: 'svg',
    });
    Lottie.loadAnimation({
      container: ProcessAnimTwo.current,
      path: '/lottie/process-2.json',
      loop: true,
      autoplay: true,
      renderer: 'svg',
    });
    Lottie.loadAnimation({
      container: ProcessAnimThree.current,
      path: '/lottie/process-3.json',
      loop: true,
      autoplay: true,
      renderer: 'svg',
    });
    if (!isMobile) {
      Lottie.loadAnimation({
        container: debitCard.current,
        path: '/lottie/debit-card.json',
        loop: true,
        autoplay: true,
        renderer: 'svg',
      });
    }
  }, [Lottie]);

  useEffect(() => {
    gsap.to(timelineLine.current, {
      scrollTrigger: {
        start: '30% 90%',
        end: '80% 70%',
        trigger: timelineBlock.current,
        // markers: true,
        scrub: 0.6,
        once: true,
      },

      scaleY: 1,
    });

    gsap.to('.golden-dot', {
      scrollTrigger: {
        start: '30% 90%',
        end: '80% 70%',
        trigger: timelineBlock.current,
        // markers: true,
        scrub: 0.6,
        once: true,
      },

      top: '100%',
    });

    const textTimelines = gsap.utils.toArray('.single-timeline');
    textTimelines.forEach((textTimeline) => {
      gsap.fromTo(
        textTimeline,
        { y: 150 },
        {
          scrollTrigger: {
            start: '-100 90%',
            end: '50% 70%',
            trigger: textTimeline,
            // markers: true,
          },

          y: 0,
          autoAlpha: 1,
        },
      );
    });
  }, []);

  useEffect(() => {
    gsap.utils.toArray('.counts').forEach((element) => {
      const clean = (v) => `${v}`.replace(/[^\d.-]/gi, '');
      const num = clean(element.getAttribute('data-number'));
      const decimals = (num.split('.')[1] || '').length;
      // proxy = {val: parseFloat(clean(element.innerText)) || 0};
      const proxy = { val: 0 };
      gsap.to(proxy, {
        val: +num,
        duration: 2,
        scrollTrigger: {
          trigger: element,
          toggleActions: 'restart none none none',
        },
        // eslint-disable-next-line no-return-assign
        onUpdate: () => (element.innerText = formatNumber(proxy.val, decimals)),
      });
    });

    function formatNumber(value, decimals) {
      const s = (+value).toLocaleString('en-US').split('.');
      return decimals
        ? `${s[0]}.${`${s[1] || ''}00000000`.substr(0, decimals)}`
        : s[0];
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="Your Global Private Bank | Aeldra"
        description="Exclusive U.S. Banking for Indians. Open a U.S. bank account in 10 minutes from India if you have an Indian Passport. No Visa, Social Security Number Required."
      />
      <HeroSectionHome />

      <HomeSectionOne />

      <section className="section-pad black-bg">
        <div className="container">
          <div className="text-center">
            <h2 className="heading white">We wish it was complicated</h2>
            <p className="subhead white">But it is pretty straight-forward</p>
            <Button href="https://prod.aeldra.com/get-started" whiteButton>
              Check Eligibility
            </Button>
          </div>
          <div className="process-block-main">
            <div className="row">
              <div className="col-md col-8 offset-2 tns-arrows-mobile">
                <div className="process-slider ">
                  <div className="item">
                    <div className="single-process-step flex align-center">
                      <div ref={ProcessAnimOne} />

                      <h3>10 Minute Digital Account* Opening</h3>
                    </div>
                  </div>

                  <div className="item">
                    <div className="single-process-step flex align-center">
                      <div ref={ProcessAnimTwo} />

                      <h3>No need to visit a branch / Stand in-line</h3>
                    </div>
                  </div>

                  <div className="item">
                    <div className="single-process-step flex align-center">
                      <div ref={ProcessAnimThree} />

                      <h3>
                        Quick, Easy,
                        <br />
                        BS Free
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden-mobile">
            <h2
              className="text-center heading white mx-auto"
              style={{ maxWidth: 760 }}>
              With Aeldra, you embrace the world
            </h2>

            <div className="row">
              <div className="col-md col-10 offset-1">
                <div className="timeline-block" ref={timelineBlock}>
                  <span className="golden-dot" />
                  <span className="timeline-line" ref={timelineLine} />
                  <div className="top-timeline">
                    <img src="/img/globe-dotted.png" alt="" />
                  </div>
                  <div className="timeline-content">
                    <div className="single-timeline">
                      <h3>
                        High Yield U.S. FDIC-Insured Bank Account* from India
                      </h3>
                    </div>
                    <div className="single-timeline">
                      <h3>Customized Full Functionality Mobile Banking*</h3>
                    </div>
                    <div className="single-timeline">
                      <h3>24x7 Concierge</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button href="https://prod.aeldra.com/get-started" whiteButton>
                Open a U.S. Bank Account
              </Button>
            </div>
          </div>
        </div>

        <PhoneTextScroll />
      </section>

      <section className="section-gap bg-lightgrey do-more-block">
        <div className="container">
          <div className="vertical-text-anim">
            {homeCtaContent.map(({ id, heading }) => (
              <div
                key={id}
                className={`vertical-content-block text-center ${
                  id === VerticalTextAnimTwo ? 'active' : ''
                }`}>
                <h2 className="heading" style={{ marginBottom: 0 }}>
                  With Aeldra
                </h2>
                <div className="masked-item">
                  <h2 className="heading text-center vertical-anim-object">
                    {heading}
                  </h2>
                </div>
              </div>
            ))}
            <p className="subhead text-center">
              Save up to $15 on every Incoming International Wire vs Major U.S.
              Banks
            </p>
            <div className="text-center" style={{ marginTop: 35 }}>
              <Button href="https://prod.aeldra.com/get-started">
                Open a U.S. Bank Account
              </Button>
            </div>
          </div>
        </div>

        <img src="/img/dotted-lines.svg" className="dotted-line-bg" alt="" />
      </section>

      <section className="section-pad black-bg">
        <div className="container">
          <h2 className="heading text-center">
            Home&apos;s Closer with Aeldra Pay
          </h2>
          <p className="subhead text-center">
            Send funds instantly between Aeldra Accounts
          </p>

          <div className="aeldra-pay-block relative">
            <Button href="https://prod.aeldra.com/get-started" whiteButton>
              Open a U.S. Bank Account
            </Button>

            <video
              src={
                breakpoints.sm
                  ? '/video/aeldra-pay-mobile.mp4'
                  : '/video/aeldra-pay.mp4'
              }
              muted
              autoPlay
              loop
              playsInline></video>
          </div>
        </div>

        <div className="marquee-block">
          <div className="container">
            <div className="vertical-text-anim">
              {homeMarqueeContent.map(({ id, heading, content }) => (
                <div
                  key={id}
                  className={`vertical-content-block ${
                    id === VerticalTextAnim ? 'active' : ''
                  }`}>
                  <div className="masked-item">
                    <h2 className="heading text-center vertical-anim-object">
                      {heading}
                    </h2>
                  </div>
                  <div className="masked-item">
                    <p className="subhead text-center vertical-anim-object">
                      {content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="marquee-slider">
            <div className="item">
              <img src="/img/aeldra-debit-card.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/transfer-money.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/aeldra-elements.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/aeldra-elements-2.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/aeldra-debit-card.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/transfer-money.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/aeldra-elements.png" alt="" />
            </div>
            <div className="item">
              <img src="/img/aeldra-elements-2.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad bg-lightgrey">
        <div className="container">
          <div className="row">
            <div className="col-md col-10 offset-1 text-center">
              <h2 className="heading ">
                Our Global Mastercard^ Debit Card Has more than just good looks
              </h2>
              <p className="subhead">
                Easy lock/unlock through the Aeldra App Contactless
              </p>

              <div
                className="global-debit-card hidden-mobile"
                ref={debitCard}
              />

              <img
                src="/img/debit-card-asset-mobile.png"
                className="global-debit-card visible-mobile"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-pad">
        <div className="container">
          <h2 className="heading text-center">
            Security first. Second. and Third
          </h2>
          <p className="subhead text-center">Experience Triple Security</p>
          <div className="security-block relative tns-arrows-mobile">
            <div className="security-slider relative" style={{ zIndex: 2 }}>
              <div className="item">
                <div className="single-security-block text-center">
                  <img src="/img/hold-funds-in-usd.svg" alt="" />
                  <p>Hold funds in USD - Global safe currency of choice</p>
                </div>
              </div>
              <div className="item">
                <div className="single-security-block text-center">
                  <img src="/img/fdic-insured.svg" alt="" />
                  <p>
                    FDIC-Insured - deposits upto $250,000 insured by U.S.
                    Federal Govt.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="single-security-block text-center">
                  <img src="/img/mastercard-zero-liability.svg" alt="" />
                  <p>MasterCard^ Zero Liability</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <p className="text-center" style={{ opacity: 0.5, marginBottom: 40 }}>
          Please Note: Valid SSN Holders can also apply
          <br />
          *Aeldra is a Fintech providing Banking* Services with FDIC insurance
          through Blue Ridge Bank, N.A., Member FDIC.
        </p>
      </div>

      <section className="section-pad bg-lightgrey">
        <div className="container">
          <h2 className="heading text-center">What are you waiting for?</h2>

          <div className="counter-block-main">
            <div className="row">
              <div className="col col-4">
                <div className="single-counter">
                  <h3 className="font-body">
                    $<span className="counts" data-number="14000000"></span>+
                  </h3>
                  <p>In Transactions</p>
                </div>
              </div>
              <div className="col col-4">
                <div className="single-counter">
                  <h3 className="font-body">
                    <span className="counts" data-number="40000"></span>+
                  </h3>
                  <p>In Customers</p>
                </div>
              </div>
              <div className="col col-4">
                <div className="single-counter">
                  <h3 className="font-body">
                    <span className="counts" data-number="783"></span>+
                  </h3>
                  <p>Cities</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button href="https://prod.aeldra.com/get-started">
              Open a U.S. Bank Account
            </Button>
          </div>
        </div>
      </section>

      <section className="section-pad testimonial-block relative">
        <div className="small-container">
          <h2 className="heading text-center">People are talking about us</h2>
          <div className="row">
            {/* <div className="col col-6">
              <div className="testimonial-image-block relative">
                <div
                  ref={controlTesimonial}
                  className="controls-tetsimonial"
                  aria-label="Carousel Navigation">
                  <button
                    className="testimonial-prev"
                    data-controls="prev"
                    aria-controls="customize"
                    tabIndex="-1"
                    type="button">
                    Prev
                  </button>
                  <button
                    className="testimonial-next"
                    data-controls="next"
                    aria-controls="customize"
                    tabIndex="-1"
                    type="button">
                    Next
                  </button>
                </div>
                <div className="testimonial-slider">
                  <div className="item">
                    <div className="testimonial-image">
                      <img src="/img/testimonial.jpg" alt="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimonial-image">
                      <img src="/img/testimonial-2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimonial-image">
                      <img src="/img/testimonial.jpg" alt="" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimonial-image">
                      <img src="/img/testimonial-2.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col col-12">
              <div className="testimonial-content-main tns-arrows-mobile">
                <div className="testimonial-slider-content">
                  <div className="item">
                    <div className="testimonial-desc">
                      <p>
                        “For long, I have been encouraging friends, family and
                        clients to diversify their investments and invest in the
                        U.S. Doing so without a bank account* is often daunting.
                        Aeldra allows me to open a U.S. bank account* remotely
                        from India. The process is quite easy and hassle-free”
                      </p>

                      <div className="testimonial-author">
                        <h4 className="font-body">Amit Wilson</h4>
                        <p>Entrepreneur and Investment Professional</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimonial-desc">
                      <p>
                        “Aeldra fulfills a very vital gap for frequent global
                        business travelers like myself. This account* with debit
                        card makes it not only very convenient to use during my
                        travels in the U.S. but also helps me save a lot in
                        forex and other bank charges.”
                      </p>

                      <div className="testimonial-author">
                        <h4 className="font-body">Neeraj Sinha</h4>
                        <p>IT professional &amp; Frequent Business Traveller</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimonial-desc">
                      <p>
                        “Aeldra&apos;s on-boarding experience is truly
                        one-of-a-kind and very intuitive. I was able to open an
                        account* using my mobile in a few simple steps, within a
                        few minutes and even get my debit card instantly.”
                      </p>

                      <div className="testimonial-author">
                        <h4 className="font-body">Satish Dasam</h4>
                        <p>Business Executive and Technology Leader</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimonial-desc">
                      <p>
                        “Aeldra&apos;s ability to open accounts with only Indian
                        passport is a great boon for students like me going to
                        the US. Now I can easily get living expenses credited
                        into my bank account* without having to go through the
                        University.”
                      </p>

                      <div className="testimonial-author">
                        <h4 className="font-body">Lakshya A</h4>
                        <p>Aspiring Student for advanced studies in US</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NewsBlock />

      <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-3">
              <h2 className="heading">We're all ears.</h2>
              <p className="subhead">
                There&apos;s no such thing as a stupid question.
              </p>

              <div className="home-faq">
                <Accordion />
              </div>
              <div style={{ marginTop: 40 }}>
                <Button href="/faq">View all FAQs</Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightgrey section-gap">
        <div className="container">
          <p className="subhead text-center">
            Click on the boxes to get instructions that you can download for
            easy reference
          </p>
          <div className="faq-pdf-main">
            <div className="row">
              <div className="col col-3">
                <a
                  href="/pdf/how-do-i-find-my-account-number.pdf"
                  className="faq-pdf-single">
                  <h3 className="gold body-font">How do I</h3>

                  <p>
                    find my Account* Number, ABA / Routing Number, Customer
                    Service Number, Virtual Debit Card^ (for U.S. residents)?
                  </p>
                </a>
              </div>
              <div className="col col-3">
                <a
                  href="/pdf/how-do-i-transfer-funds-from-india-to-aeldra-account.pdf"
                  className="faq-pdf-single">
                  <h3 className="gold body-font">How do I</h3>

                  <p>transfer funds from India to my Aeldra account*?</p>
                </a>
              </div>
              <div className="col col-3">
                <a
                  href="/pdf/transfer-funds-from-aeldra-to-india.pdf"
                  className="faq-pdf-single">
                  <h3 className="gold body-font">How do I</h3>

                  <p>
                    transfer funds from Aeldra to India while living in India?
                  </p>
                </a>
              </div>
              <div className="col col-3">
                <a
                  href="/pdf/transfer-funds-from-us-to-aeldra.pdf"
                  className="faq-pdf-single">
                  <h3 className="gold body-font">How do I</h3>

                  <p>
                    transfer funds to a U.S. bank account* from my Aeldra
                    account*?
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Homepage;
