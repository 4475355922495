import React, {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from 'react';
import './HomeSectionOne.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { sectionOneAsset3Texts } from '../../../utils/contentProvider';

gsap.registerPlugin(ScrollTrigger);
function HomeSectionOne() {
  const section1 = useRef();
  const section1Asset1 = useRef();
  const section1Asset2 = useRef();
  const section1Asset3 = useRef();
  const section1Asset4 = useRef();
  const phoneScreenAsset1 = useRef();
  const phoneScreenAsset2 = useRef();
  const secondAssetMobile = useRef();

  const [section1Asset3TextAnim, setsection1Asset3TextAnim] = useState(0);

  const updatesection1Asset3TextAnim = useCallback(() => {
    setsection1Asset3TextAnim((state) => (state === 5 ? 0 : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updatesection1Asset3TextAnim(), 3000);

    return () => clearInterval(interval);
  }, [updatesection1Asset3TextAnim]);

  const tl = useRef();

  useLayoutEffect(() => {
    const wrapText = gsap.utils.toArray('.wrap-words.heading');
    wrapText.forEach((textWrap) => {
      gsap.from(textWrap, {
        scrollTrigger: {
          start: '0 50%',
          end: '50% 0',
          trigger: textWrap,
          onUpdate: (self) => {
            if (self.progress > 0) {
              textWrap.classList.add('active');
            }
          },
        },
      });
    });
  }, []);

  useLayoutEffect(() => {
    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: section1.current,
          start: 'top 50%',
          end: '70% 40%',
          scrub: 0.6,
          // markers: true,
        },
      })
      .from(section1Asset1.current, {
        autoAlpha: 0,
        x: '80%',
        duration: 1,
        ease: ' Power3. easeInOut',
      })
      .fromTo(
        section1Asset1.current,
        { autoAlpha: 1, scale: 1 },
        {
          autoAlpha: 0,
          scale: 0,
          duration: 1,
          ease: ' Power3. easeInOut',
        },
        '+=1',
      );

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: section1.current,
          start: 'top 50%',
          end: '70% 44%',
          scrub: 0.6,
          // markers: true,
        },
      })
      .from(section1Asset2.current, {
        autoAlpha: 0,
        y: '100%',
        duration: 1,
        ease: ' Power3. easeInOut',
      })

      .fromTo(
        section1Asset2.current,
        { autoAlpha: 1, scale: 1 },
        {
          autoAlpha: 0,
          y: '-100%',
          duration: 1,
          ease: ' Power3. easeInOut',
        },
        '+=1',
      );
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width:768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: section1.current,
              start: '60% 50%',
              end: '70% 44%',
              // scrub: true,
              // markers: true,
              toggleActions: 'play none reverse none',
            },
          })
          .to(phoneScreenAsset1.current, {
            x: '-100%',
            duration: 0.5,
            ease: ' Power3. easeInOut',
          })
          .fromTo(
            phoneScreenAsset2.current,
            { x: '100%' },
            {
              x: 0,
              duration: 0.5,
              ease: ' Power3. easeInOut',
            },
            '0',
          );
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: section1.current,
              start: '60% 50%',
              end: '78% 44%',
              scrub: 0.6,
              // markers: true,
            },
          })
          .from(section1Asset3.current, {
            autoAlpha: 0,
            x: '20%',
            duration: 1,
            ease: ' Power3. easeInOut',
          })
          .from(section1Asset4.current, {
            autoAlpha: 0,
            x: '-20%',
            duration: 1,
            ease: ' Power3. easeInOut',
          });
      },
    });
    ScrollTrigger.matchMedia({
      '(max-width:767px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: section1.current,
              start: '60% 50%',
              end: '70% 44%',
              // markers: true,
              once: true,
            },
          })
          .from(secondAssetMobile.current, {
            x: '300',
            autoAlpha: 0,
            duration: 0.5,
            ease: ' Power3. easeInOut',
          });
      },
    });
  }, [tl]);
  return (
    <section className="section-pad home-section-one" ref={section1}>
      <div className="container">
        <div className="global-dreams-block">
          <div className="row">
            <div className="col col-4">
              <h2 className="heading wrap-words">
                Make us a part of your global growth story
              </h2>
            </div>

            <div className="col col-4">
              <div className="section-1-mobile">
                <div className="phone-screen">
                  <img src="/img/phone-screen-1.png" alt="" />

                  <div
                    className="absolute phone-screen-asset"
                    ref={phoneScreenAsset1}>
                    <img src="/img/section-1-phone-asset-1.svg" alt="" />
                  </div>
                  <div
                    className="absolute phone-screen-asset phone-screen-asset-2 hidden-mobile"
                    ref={phoneScreenAsset2}>
                    <img src="/img/section-1-phone-asset-2.svg" alt="" />
                  </div>
                </div>

                <div className="absolute asset-1" ref={section1Asset1}>
                  <img src="/img/section-1-asset-2.svg" alt="" />
                </div>
                <div className="absolute asset-2 " ref={section1Asset2}>
                  <img src="/img/section-1-asset-1.svg" alt="" />
                </div>

                <div
                  className="absolute asset-3 hidden-mobile"
                  ref={section1Asset3}>
                  <div className="dotted-circle">
                    <svg fill="none" viewBox="0 0 433 433">
                      <circle
                        cx="216.5"
                        cy="216.5"
                        r="215.5"
                        stroke="#1B1A1A"
                        strokeDasharray="1 6"
                        strokeLinecap="round"
                        strokeLinejoin="bevel"
                      />
                    </svg>
                    <div className="dc-items">
                      <div className="dc-item absolute">
                        <p>Students</p>
                        <img src="/img/students.png" alt="" />
                      </div>
                      <div className="dc-item absolute">
                        <p>Travellers</p>
                        <img src="/img/travellers.png" alt="" />
                      </div>
                      <div className="dc-item absolute">
                        <p>Investors</p>
                        <img src="/img/investors.png" alt="" />
                      </div>
                      <div className="dc-item absolute">
                        <p>Professionals</p>
                        <img src="/img/professionals.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="asset-3-texts">
                    {sectionOneAsset3Texts.map(({ id, content }) => (
                      <p
                        key={id}
                        className={`wrap-words ${
                          id === section1Asset3TextAnim ? 'active' : ''
                        }`}>
                        {content}
                      </p>
                    ))}
                  </div>
                </div>

                <div
                  className="absolute asset-4 hidden-mobile"
                  ref={section1Asset4}>
                  <img src="/img/section-1-asset-3.svg" alt="" />
                </div>
              </div>
            </div>

            <div className="col col-4">
              <div className="global-dreams-content-2">
                <h2 className="heading wrap-words">
                  One stop solution to all your global banking* needs
                </h2>

                <div
                  className="phone-mobile-block visible-mobile"
                  ref={secondAssetMobile}>
                  <img src="/img/one-stop-solution-mobile.png" alt="" />

                  <div className="absolute asset-3 ">
                    <div className="dotted-circle">
                      <svg fill="none" viewBox="0 0 433 433">
                        <circle
                          cx="216.5"
                          cy="216.5"
                          r="215.5"
                          stroke="#1B1A1A"
                          strokeDasharray="1 6"
                          strokeLinecap="round"
                          strokeLinejoin="bevel"
                        />
                      </svg>
                      <div className="dc-items">
                        <div className="dc-item absolute">
                          <p>Students</p>
                          <img src="/img/students.png" alt="" />
                        </div>
                        <div className="dc-item absolute">
                          <p>Travellers</p>
                          <img src="/img/travellers.png" alt="" />
                        </div>
                        <div className="dc-item absolute">
                          <p>Investors</p>
                          <img src="/img/investors.png" alt="" />
                        </div>
                        <div className="dc-item absolute">
                          <p>Professionals</p>
                          <img src="/img/professionals.png" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="asset-3-texts">
                      {sectionOneAsset3Texts.map(({ id, content }) => (
                        <p
                          key={id}
                          className={`wrap-words ${
                            id === section1Asset3TextAnim ? 'active' : ''
                          }`}>
                          {content}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSectionOne;
