import React, { useLayoutEffect, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './HeroSectionHome.scss';
import Button from '../../Button/Button';

gsap.registerPlugin(ScrollTrigger);

const homHeroContent = [
  {
    id: 0,
    heading: 'Global Mobile Banking* from India',
  },
  {
    id: 1,
    heading: 'Open U.S. Bank Account* Digitally in 10 mins',
  },
  {
    id: 2,
    heading: 'Only Indian Passport Required',
  },
  {
    id: 3,
    heading: 'Global Debit Card on Arrival in U.S.',
  },
];

function HeroSectionHome() {
  const breakpoints = useBreakpoint();

  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const heroSliderContainer = document.querySelector('.hero-home-slider');

      if (heroSliderContainer) {
        tns({
          container: heroSliderContainer,
          autoplay: true,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          mode: 'gallery',
          gutter: 0,
          speed: 1000,
          controls: false,
          nav: false,

          responsive: {
            0: {
              items: 1,
            },

            768: {
              items: 1,
            },

            1200: {
              items: 1,
            },
          },
        });
      }
    });
  }, []);

  // const [VerticalTextAnim, setVerticalTextAnim] = useState(0);

  // const updateVerticaltextAnim = useCallback(() => {
  //   setVerticalTextAnim((state) => (state === 1 ? 0 : state + 1));
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => updateVerticaltextAnim(), 4000);

  //   return () => clearInterval(interval);
  // }, [updateVerticaltextAnim]);

  useLayoutEffect(() => {
    const wrapText = gsap.utils.toArray('.wrap-words.heading');
    wrapText.forEach((textWrap) => {
      gsap.from(textWrap, {
        scrollTrigger: {
          start: '0 50%',
          end: '50% 0',
          trigger: textWrap,
          onUpdate: (self) => {
            if (self.progress > 0) {
              textWrap.classList.add('active');
            }
          },
        },
      });
    });
  }, []);
  return (
    <section className="hero-scroll">
      <div className="hero-section">
        <div className="fixed-hero">
          <div className="container relative">
            <div className="hero-right-content">
              {/* <div className="slide-numbers">
                <span className={heroActive === 0 ? 'active' : ''}>01</span>
                <span className={heroActive === 1 ? 'active' : ''}>02</span>
                <span className={heroActive === 2 ? 'active' : ''}>03</span>
                <span className={heroActive === 3 ? 'active' : ''}>04</span>
              </div> */}
              <div className="scroll-down">
                <span>SCROLL DOWN</span>
              </div>
            </div>
            <div className="hero-content ">
              <div className="text-center">
                <div className="hero-home-slider">
                  {homHeroContent.map(({ id, heading }) => (
                    <div key={id} className="item">
                      <div className="masked-item">
                        <div className="content ">
                          <h1 className="heading text-center vertical-anim-object">
                            {heading}
                          </h1>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Button href="https://prod.aeldra.com/get-started">
                  Open a U.S. Bank Account
                </Button>
              </div>
            </div>

            <div className="hero-anim">
              <div className="relative ">
                <video
                  src={
                    breakpoints.sm
                      ? '/video/hero-mobile.mp4'
                      : '/video/herovideo.mp4'
                  }
                  muted
                  autoPlay
                  loop
                  playsInline></video>
              </div>
            </div>

            <p className="hero-bottom-text text-center">
              Please Note: Valid SSN Holders can also apply
              <br />
              *Aeldra is a Fintech providing Banking* Services with FDIC
              insurance through Blue Ridge Bank, N.A., Member FDIC.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSectionHome;
