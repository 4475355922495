import React, { useEffect } from 'react';
// import { tns } from 'tiny-slider/src/tiny-slider';
import { newsContent } from '../../utils/contentProvider';
import Button from '../Button/Button';

function NewsBlock() {
  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const newsSliderContainer = document.querySelector('.news-block-main');

      if (newsSliderContainer) {
        const newsSlider = tns({
          container: newsSliderContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: true,
          loop: false,
          controls: true,
          nav: false,
          gutter: 24,
          responsive: {
            0: {
              items: 1.2,
              controls: false,
            },

            768: {
              items: 2.2,
            },

            1200: {
              controls: true,
              items: 3,
            },
          },
        });
      }
    });
  }, []);

  return (
    <section className="section-pad bg-lightgrey">
      <div className="container">
        <h2 className="heading text-center heading-mb-50">
          Aeldra in the news
        </h2>
        <div className=" equal-height">
          <div className="news-block-main">
            {newsContent.map(({ id, logo, desc, url }) => (
              <div key={id} className="item">
                <div className="single-news-block">
                  <div className="news-img">
                    <img src={logo} alt="" />
                  </div>

                  <p>{desc}</p>

                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="gold">
                    Read more{' '}
                    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
                      <path
                        fill="#BEB281"
                        d="m7.895 4.121-.003 1.5 3.418.008-7.203 7.188 1.06 1.062 7.222-7.208-.008 3.46 1.5.004.014-6-6-.014Z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsBlock;
