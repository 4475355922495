import { useEffect, useState } from 'react';

const windowGlobal = typeof window !== 'undefined' && window;

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(() =>
    typeof windowGlobal === 'object'
      ? windowGlobal.matchMedia(query).matches
      : false,
  );
  useEffect(() => {
    const mql = windowGlobal.matchMedia(query);
    const onChange = (e) => setMatches(e.matches);
    mql.addListener(onChange);
    return () => {
      mql.removeListener(onChange);
    };
  }, [query]);
  return matches;
};

export default useMediaQuery;
